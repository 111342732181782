import React, { useEffect, useState } from 'react'
import './style.scss';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../Utils/helpers/Hooks/useTypedSelector';
import { fetchBlogContents } from '../../../store/redux/blogSlice';
import { Pagination } from '../../../Utils/Model';
import moment from 'moment';
import Subscribe from '../../Subscribe';
import { BsArrowUpRight } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import Loaders from '../../loaders';
import Paginate from '../../../Utils/helpers/Pagination/Paginate';
import { pagination } from '../../../store/redux/paginateSlice';
import DOMPurify from "dompurify";

const News: React.FC = () => {
    const dispatch = useAppDispatch();
    const {loading, error, blogs} = useAppSelector(state => state.blogs)
    const alldata = blogs?.data
    const [currentPage, setCurrentPage] = useState(alldata?.current_page ?? 1)
    const [perPage] = useState(alldata?.per_page ?? 6);
    
    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber)
    }

    const htmlDecode = (content: any) => {
        let e = document.createElement("div");
        e.innerHTML = content;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
      };

    const goToNextOrPreviousPage = (type: string) => {
        switch (type) {
            case 'previous':
                const previous_page = currentPage - 1;
                setCurrentPage(previous_page)
                break;
            case 'next':
                const next_page = currentPage + 1;
                setCurrentPage(next_page)
                break;
            default:
                return type;
        }
    }

    useEffect(() => {
        dispatch(fetchBlogContents(currentPage))
    }, [dispatch, currentPage])


    

  return (
      <>
      <Subscribe subject={'Trav4college blog'} content={"Subscribe to get latest updates!"} />

      {
          loading ? 
          <Loaders/>
        : 
        <>
                {
                    alldata?.data?.map(({content, blog_img, header, id, created_at, blogger}) => 
                    <>
                    {
                        blog_img ? <>
                                    <div className="flex flex-col justify-center my-12 border-b-4 border-gray-100" key={id}>
                                        <div 
                                            className="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl max-w-xs md:max-w-6xl mx-auto mb-12 font-SamoSansReg">
                                           <div className="w-full md:w-1/2 bg-white grid place-items-center">
                                                <img src={blog_img} alt="tailwind logo" className="hover:animate-pulse rounded-xl" />
                                        </div>
                                                <div className="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
                                                    <h3 className="font-black text-gray-800 md:text-3xl text-xl" dangerouslySetInnerHTML={{ __html: header }}/>
                                                    <div className="flex justify-between item-center">
                                                        <p className="text-gray-500 font-medium hidden md:block italic">{moment(created_at).format('MMM Do YYYY')}</p>
                                                    </div>
                                                    <p className="md:text-lg text-gray-500 text-base mb-12 content" dangerouslySetInnerHTML={{ __html: content}}
                                                    />
                                                    <div className="flex items-center space-x-4 py-5 justify-between">
                                                    <Link to={`readmore/${id}/${encodeURIComponent(header)}`}>
                                            <button className="readmoreBtn flex space-x-2 items-center px-3 py-2 rounded-md drop-shadow-md">
                                                <span className="text-white">Read more</span>
                                                <IconContext.Provider value={{ color: 'fff' }}>
                                            <BsArrowUpRight/>
                                            </IconContext.Provider>
                                            </button>
                                            </Link>
                                                    <div className="flex items-center" title='writer'>
                                            <img className="w-10 h-10 rounded-full mr-4" src="/avatar.png" alt="Avatar of Writer"/>
                                            <div className="text-sm">
                                            <p className="text-gray-900 leading-none">Admin Trav4college</p>
                                                {/* <p className="text-gray-900 leading-none">{blogger?.admin_name}</p>
                                                <p className="text-gray-600">{blogger?.post_held}</p> */}
                                            </div>
                                            </div>
                                                    </div>
                                                    {/* </p> */}
                                                </div>
                                            </div>

                                            
                                        </div>
                        </>

                        :

                        <div className="flex flex-col justify-center my-12 border-b-4 border-gray-100" key={id}>
                        <div 
                            className="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl max-w-xs md:max-w-6xl mx-auto mb-12">
                                <div className="w-full bg-white flex flex-col space-y-2 p-3">
                                    <h3 className="font-black text-gray-800 md:text-3xl text-xl" dangerouslySetInnerHTML={{ __html: header }}/>
                                    <div className="flex justify-between item-center">
                                        <p className="text-gray-500 font-medium hidden md:block italic">{moment(created_at).format('MMM Do YYYY')}</p>
                                    </div>
                                    <p className="md:text-lg text-gray-500 text-base mb-12 content" dangerouslySetInnerHTML={{ __html: content}}/>
                                    <div className="flex items-center space-x-4 py-5 justify-between">
                                    <Link to={`readmore/${id}/${encodeURIComponent(header)}`}>
                            <button className="readmoreBtn flex space-x-2 items-center px-3 py-2 rounded-md drop-shadow-md">
                                <span className="text-white">Read more</span>
                                <IconContext.Provider value={{ color: 'fff' }}>
                            <BsArrowUpRight/>
                            </IconContext.Provider>
                            </button>
                            </Link>
                                    <div className="flex items-center" title='writer'>
                            <img className="w-10 h-10 rounded-full mr-4" src="/avatar.png" alt="Avatar of Writer"/>
                            <div className="text-sm">
                                <p className="text-gray-900 leading-none">Admin Trav4college</p>
                                {/* <p className="text-gray-600">{blogger?.post_held}</p> */}
                            </div>
                            </div>
                                    </div>
                                    {/* </p> */}
                                </div>
                            </div>

                            
                        </div>

                    }

                  

                    </>
                    
                    )
                }
                
                    <Paginate per_page={perPage} current_page={currentPage} total={alldata?.total} paginate={paginate} goToNextOrPreviousPage={goToNextOrPreviousPage}/>
               
                
        </>
      }
      </>
  )
}

export default News
