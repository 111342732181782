import React from 'react'
import Header from './Header'
import News from './News'
import {Props as TProps} from '../../Utils/Model'

const TimeLine: React.FC<TProps> = () =>{
  return (
    <>
    <Header header='Latest News'/>
    <News/>
  </>
  )
}

export default TimeLine
