export const lists = [
  {
    "name": "Home",
    "link": "/"
  },

  {
    "name": "Blog",
    "link": "/"
  },

  {
    "name": "Website",
    "link": "https://trav4college.com"
  },

  {
    "name": "Why trav4college",
    "link": "https://trav4college.com/about/"
  },
]