import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { fetchBlogbyId } from '../../store/redux/fetchBlogSlice';
import { useAppDispatch, useAppSelector } from '../../Utils/helpers/Hooks/useTypedSelector';
import { Blog } from '../../Utils/Model';
import Header from '../Timeline/Header';
import moment from 'moment';
import { IconContext } from 'react-icons';
import { BsArrowLeft, BsArrowUpRight, BsFacebook } from 'react-icons/bs';
import parse from 'html-react-parser';
import ReadNext from '../ReadNext';
import './style.css';
import { AiFillTwitterCircle, AiOutlineWhatsApp } from 'react-icons/ai';

function Readmore() {
  const {id} = useParams();
  const [link, setLink] = useState('');
  const dispatch = useAppDispatch()
  const location = useLocation();
  const {blog, loading, error} = useAppSelector(state => state.fetchBlog)

  useEffect(() => {
     dispatch(fetchBlogbyId(Number(id)))
  }, [dispatch, id])

  const htmlDecode = (content: any) => {
    let e = document.createElement("div");
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };
  
  const handleShare = (type: string) => {
    setLink(location.pathname)
     switch (type) {
      case 'whatsapp':
        window.open(`whatsapp://send?text= ${window.location.href}   Hi, Can you spare some few minutes to check out this article on trav4college blog, seems like you need it`);
        break;
      case 'twitter':
        window.open(`http://twitter.com/share?text=Want to travel outside and study? Check out this article on trav4college&url=${window.location.href}`);
        break;
      case 'facebook':
            window.open(`https://www.facebook.com/share.php?u=${window.location.href}`)
          break;
      default:
        break;
     }
  }

  return (
    <>
     <Header header="Back home"/>
     <div className="flex items-center py-12 px-14 md:px-14 justify-between ck-content">
                <Link to='/'>
        <button className="flex space-x-2 items-center px-3 py-2 font-black text-1xl rounded-md drop-shadow-md">
            <IconContext.Provider value={{ color: '000' }}>
        <BsArrowLeft/>
        </IconContext.Provider>
            <span className="text-black">Trav4College Blog</span>
        </button>
        </Link>
        <div className="flex items-center mx-8 top-80 left-56 " title='writer'>
          <img className="w-10 h-10 rounded-full mr-4" src="/avatar.png" alt="Avatar of Writer"/>
          <div className="text-sm">
            {/* <p className="text-gray-900 leading-none font-black text-1xl">{blog?.data?.blogger?.admin_name}</p>
            <p className="text-gray-600">{blog?.data?.blogger?.post_held}</p> */}
            <p className="text-gray-600">Admin Trav4College</p>
          </div>
        </div>
                </div>
     <div className="px-12 mx-auto" >
  
  <div className="max-w-12xl mx-auto flex flex-col justify-center items-center">
  <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0 text-center mb-12">
      <h1 className="text-gray-900 text-5xl title-font font-medium mb-1" dangerouslySetInnerHTML={{ __html: typeof blog?.data.header === 'string' ? blog?.data.header : '' }}/>
</div>
    {
      blog?.data.blog_img  &&
      <img alt="ecommerce" className="lg:w-3/6 w-full lg:h-auto h-64 object-cover object-center rounded" src={blog?.data.blog_img} />
    }
    <div className="lg:w-1/2 w-full lg:py-6 mt-6 lg:mt-0">
      <h2 className="text-sm title-font text-gray-500 mb-2 italic">Published on {moment(blog?.data.created_at).format('MMM Do YYYY')}</h2>
      <div className="leading-relaxed full_content" dangerouslySetInnerHTML={{ __html: typeof blog?.data.content === 'string' ? blog?.data.content : '' }}/>

      {/* <p>{parse(typeof blog?.data.content === 'string' ? blog?.data.content : '' )}</p> */}

      <h2 className="text-sm title-font text-gray-500 mt-12 underline">Share article</h2>
      <div className="flex items-center sm:grid-cols-1 md:grid-cols-3">
        <button title='Share via twitter' onClick={()=>handleShare('twitter')} className="flex items-center py-2 rounded-md drop-shadow-md">
            <span className="text-blue-600 text-4xl font-bold">
              <AiFillTwitterCircle/>
            </span>
        {/* <IconContext.Provider value={{ color: '326CEC' }}>
        <BsArrowUpRight/>
        </IconContext.Provider> */}
        </button>

        <button title='Share via whatsApp' onClick={()=>handleShare('whatsapp')}
            className="flex items-center px-5 py-3 rounded-full drop-shadow-md">
            <span className="text-blue-600 text-4xl font-bold">
              <AiOutlineWhatsApp/>
            </span>
             {/* <IconContext.Provider value={{ color: '326CEC' }}>
        <BsArrowUpRight/>
        </IconContext.Provider> */}
        </button>

  
        <button title='Share via facebook' onClick={()=>handleShare('facebook')}
            className="flex space-x-2 items-center py-2 rounded-full drop-shadow-md">
            <span className="text-blue-600 text-4xl font-bold">
              <BsFacebook/>
            </span>
              {/* <IconContext.Provider value={{ color: '326CEC' }}>
                <BsArrowUpRight/>
                </IconContext.Provider> */}
        </button>
    </div>
    </div>
  </div>
</div>

   {/* <ReadNext/> */}

    </>
  )
}

export default Readmore
