import React, { useState } from 'react'
import './style.scss';
import { lists } from '../../../Pages/navbarlist';
import { userPage } from '../../../Pages/user';
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import { IconContext } from 'react-icons';
// import { HashLink as Link } from 'react-router-hash-link'

function WebNav() {
  const [open, setOpen] = useState(false);

  // const handleShowNav = (event: React.MouseEvent<HTMLButtonElement>) =>{
  //    return setNavbar(true);
  // }
  return (
    <div className='bg-gradient-to-l from-gray-100 to-blue-100 w-full fixed top-0 left-0  z-20'>
    <div className='md:flex items-center justify-between bg-color py-4 md:px-10 px-7'>
    <div className='font-bold text-2xl cursor-pointer flex items-center
    text-gray-800'>
      <span className='text-3xl text-indigo-600 mr-1 pt-2 -mt-2'>
        <img src="https://trav4college.com/assets/icons/iconlogo.svg" alt="" />
      </span>
      <span className='font-SamoSansBold' style={{ color: '#000' }}>
        Trav4College
      </span>
    </div>
    
    <div onClick={()=>setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
    {/* <ion-icon name={open ? 'close':'menu'}></ion-icon> */}

     {
        open ?  
        <IconContext.Provider value={{ color: '000' }}>
          <GrClose/>
        </IconContext.Provider>
           :  
        <IconContext.Provider value={{ color: '000' }}>
          <GiHamburgerMenu/>
        </IconContext.Provider>  
     }
    </div>

    <ul className={`lists md:flex md:items-center md:pb-0 pb-12 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all font-SamoSansBold duration-500 ease-in text-blue-300 ${open ? 'top-15 ':'top-[-490px]'}`}>
      {
        lists.map((link)=>(
          <li key={link.name} className='md:ml-8 text-base md:my-0 my-7'>
            <a href={link.link} className='font-bold font-SamoSansBold hover:text-gray-400 duration-500'>{link.name}</a>
          </li>
        ))
      }

      {
        userPage.map((link) => (
           <>
            <li key={link.name} className='ml-3 my-6 text-base md:my-0 my-3'> 
              {/* <a href={link.link} className='font-black hover:text-gray-400 duration-500'>{link.name}</a> */}
              <a href={link.link} target="_blank" rel="noreferrer" className='sign-ups font-bold font-SamoSansBold py-2 px-6 rounded 
    duration-500'>
      {link.name}
    </a>
          </li>
           </>
        ))
      }
    </ul>
    </div>
  </div>
  )
}

export default WebNav
