import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { BiLoaderCircle } from "react-icons/bi";
import { subscribeToBlog } from "../../store/redux/subscribeSlice";
import { useAppDispatch, useAppSelector } from "../../Utils/helpers/Hooks/useTypedSelector";
import { SubscribeTags } from "../../Utils/Model";
import './style.scss';

const Subscribe: React.FC<SubscribeTags> = ({subject, content}) => {
    const [email, setEmail] = useState("");
    const dispatch = useAppDispatch();
    const {loading, data, error} = useAppSelector((state: any) => state.subscribe)
    
    const handleEmailChange = (event: any) => {
        setEmail(event.target.value)
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        dispatch(subscribeToBlog({email}))
    }

    return (
        <>
    <div className="container flex flex-col bg-gradient-to-l from-gray-100 to-blue-100">
        <div className="bgImage max-w-12xl bg-white-300 h-64 w-fuPl rounded-lg bg-cover bg-center">
        </div>

        <div className="-mt-40 rounded-lg overflow-hidden bg-gradient-to-l from-gray-100 to-blue-100 font-SamoSansBold">
            <div className="items-center justify-between py-10 px-5 bg-gray rounded-lg mx-auto text-center">
                <div className="px-2 -mt-16">
                    <div className="text-center bg-gray">
                        <h1 className="font-normal text-3xl text-grey-800 font-medium my-4 w-full font-SamoSansBold">{subject}</h1>
                        <p className="font-normal font-medium">{content}</p>
                        <div className="w-full text-center bg-gray">
                            <form onSubmit={handleSubmit}>
                                <div className="max-w-2xl mx-auto p-1 pr-0 flex items-center">
                                    <input type="email" placeholder="Your e-mail?"
                                        className="flex-1 appearance-none rounded shadow p-3 text-grey-dark mr-2 focus:outline-none" onChange={handleEmailChange}/>
                                        {
                                           loading ? <button disabled
                                           className="subscribe text-white text-base font-semibold rounded-md shadow-md p-3" style={{ backgroundColor: '#0480FC' }}>
                                            <IconContext.Provider value={{ color: 'fff' }}>
                                                   <BiLoaderCircle/>
                                            </IconContext.Provider>
                                         </button>
                                           :
                                    <button type="submit" disabled={data?.data}
                                        className="subscribe text-white text-base font-semibold rounded-md shadow-md p-3" style={{ backgroundColor: '#0480FC' }}>{data?.data ? data?.data : 'Subscribe!'}</button>
                                        }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
        </>
    )
}

export default Subscribe