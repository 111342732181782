import { createAction, createSlice } from '@reduxjs/toolkit';
import { Blog, FetchBlog, FetchBlogResponse } from '../../Utils/Model';
import { CONFIG } from './../../Utils/Constants/Header';
import { API } from './../../Utils/Constants/API';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    loading: false,
    blog: null,
    error: null
} as FetchBlog

// const fetchABlog = createAction<number | undefined>('blog/fetchABlog');

export const fetchBlogbyId = createAsyncThunk('blog/fetchBlogbyId', async(id: number, thunkApi) => {
  try {
    const response = await axios.get<FetchBlogResponse>(`${API}/blogs/${id}`, CONFIG)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message)
  }
})

const fetchBlogbyIdSlice = createSlice({
    name: 'blogId',
    initialState,
    reducers: {},
    extraReducers(builder){
        builder
        .addCase(fetchBlogbyId.pending, (state, action) => {
            state.loading = true
        })
        .addCase(fetchBlogbyId.fulfilled, (state, action: PayloadAction<FetchBlogResponse>) => {
            state.loading = false;
            state.blog = action.payload
        })
        .addCase(fetchBlogbyId.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export default fetchBlogbyIdSlice.reducer