import { configureStore } from '@reduxjs/toolkit';
import blogSlice from './redux/blogSlice';
import fetchBlogSlice from './redux/fetchBlogSlice';
import paginateSlice from './redux/paginateSlice';
import subscribeSlice from './redux/subscribeSlice';

export const store = configureStore({
   reducer: {
      blogs: blogSlice,
      fetchBlog: fetchBlogSlice,
      subscribe: subscribeSlice,
      paginate: paginateSlice
   }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store