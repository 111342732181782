import React from 'react';
import { Pagination } from '../../Model';

const Paginate: React.FC<Pagination> = ({per_page = 4, current_page = 1, total = 12, paginate, goToNextOrPreviousPage}) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(total / per_page); i++) {
        pageNumbers.push(i)
    }
    return (
       <>
        {
        <nav className='flex flex-col mx-auto items-center px-12'>
        <ul className="inline-flex -space-x-px mx-auto justify-center">
            <>
            <button className="py-2 px-3 leading-tight text-gray-500 bg-white" onClick={()=>goToNextOrPreviousPage('previous')}>Previous</button>
            {
                pageNumbers?.map((pageNumber: any) => 
                    <li key={pageNumber} className="px-3">
                    <button    className={
      current_page === pageNumber
        ? "bg-blue-500 border-blue-500 text-white hover:bg-blue-200 relative inline-flex items-center px-4 py-2 text-sm font-medium"
        : "text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 text-sm font-medium"
    } onClick={()=>paginate(pageNumber)}>{pageNumber}</button>
    
                    </li>
                
                )
            }
            <button className="py-2 px-3 leading-tight text-gray-500 bg-white" onClick={()=>goToNextOrPreviousPage('next')}>Next</button>
            </>
        </ul>
        </nav> 
        }
       </>
    )
}
export default Paginate