import React, {useEffect} from 'react';
import './App.scss';
import TimeLine from './components/Timeline';
import {Routes, Route} from "react-router-dom";
import WebNav from './components/Navigations/WebNav';
import Readmore from './components/Readmore';
import Footer from './components/Footer';

function App() {  
  return (
    <div className='container'>
        <WebNav/>
        <Routes>
              <Route path="/" element={<TimeLine header='Latest News'/>}/>
              <Route path="readmore/:id/:title" element={<Readmore/>}/>
              {/* <Route path="subscribe" element={<Subscribe/>}/> */}
        </Routes>
        <Footer/>
    </div>
  );
}

export default App;
