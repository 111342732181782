import React, {useState} from 'react'
import {useLocation, Link} from "react-router-dom";
import {Props as IProps} from '../../../Utils/Model'
import { BiEnvelope } from "react-icons/bi";
import { IconContext } from "react-icons";

const Header: React.FC<IProps> = ({header}) => {
  const location = useLocation()
  return (
    <header className="w-full top-12 left-0 mb-12">
    {/* <div className="mx-auto max-w-7xl py-7 px-3 sm:px-6 lg:px-8">
      <div className="flex justify-between">
        <h4 className={`${location.pathname !== '/' ? 'text-base text-gray-900 mx-12' : 'text-3xl font-bold tracking-tight text-gray-900 mx-12' }`}>{ location.pathname === '/' ? "Latest News"  : header }</h4>
    
    
    
      <Link to="/subscribe">
    <div className='flex cursor-pointer'>
        <h6 className="text-xl font-bold tracking-tight text-gray-900 mx-12 transition duration-700 ease-in-out sm:text-base md:text-base flex justify-between ">
          </h6> <span className='mt-2 text-base font-black'>Subscribe</span>
        
    </div>
    </Link>
      </div>
    </div> */}
  </header>
  )
}

export default Header