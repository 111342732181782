import { BlogState, BlogResponse } from './../../Utils/Model';
import { API } from './../../Utils/Constants/API';
import axios  from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CONFIG } from '../../Utils/Constants/Header';

const initialState = {
    loading: false,
    blogs: null,
    error: null
} as BlogState

export const pagination = createAsyncThunk('blog/pagination', async(data:number, thunkApi) => {
   try {
    const response = await axios.get<BlogResponse>(`${API}/blogs?page=${1 || data}`, CONFIG)
    return response.data
   } catch (error: any) {
     return thunkApi.rejectWithValue(error)
   }
});

const paginateSlice = createSlice({
    name: 'paginate',
    initialState,
    reducers: {},
    extraReducers (builder) {
        //cases
        builder //chainig cases
        .addCase(pagination.pending, (state) => {
            state.loading = true
        })
        .addCase(pagination.fulfilled, (state, action: PayloadAction<BlogResponse>) => {
            state.loading = false
            state.blogs = action.payload
            state.error = ''
        })
        .addCase(pagination.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload.message || 'Something is wrong'
        })
    }
})

export default paginateSlice.reducer