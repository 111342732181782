import React from 'react'
import './style.scss'
import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram, AiFillYoutube, AiFillMediumSquare, AiFillLinkedin } from "react-icons/ai";
import {BsTwitter} from 'react-icons/bs'
import Subscribe from '../Subscribe';

function Footer() {
  return (
    <>
<footer className="mt-12 bg-gradient-to-l from-gray-100 to-blue-100">
        <Subscribe subject={'Want to get more stories ?'} content={"Don't miss out on latest updates!"}/>
    
    <div className="py-5 px-12 md:flex md:items-center md:justify-between">
        <span className="text-sm sm:text-center">© 2022 <a href="https://trav4college.com/">Trav4college™</a>. All Rights Reserved.
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center md:mt-0">
            <a href="https://web.facebook.com/trav4collegehq?_rdc=1&_rdr" className="footer-item text-gray-400 hover:text-gray-900 dark:hover:text-blue-500">
               <FaFacebook/>
            </a>
            <a href="https://www.instagram.com/trav4collegehq/" className="footer-item text-gray-400 hover:text-gray-900 dark:hover:text-blue-500">
               <AiFillInstagram/>
            </a>
            <a href="https://twitter.com/trav4collegehq" className="footer-item text-gray-400 hover:text-gray-900 dark:hover:text-blue-500">
               <BsTwitter/>
            </a>
            <a href="https://www.youtube.com/channel/UCTHmrXmRZXGAmaoc4IpU-Lw" className="footer-item text-gray-400 hover:text-gray-900 dark:hover:text-blue-500">
                <AiFillYoutube/>
            </a>
            <a href="https://trav4college.medium.com/" className="footer-item text-gray-400 hover:text-gray-900 dark:hover:text-blue-500">
                <AiFillMediumSquare/>
            </a>

            <a href="https://www.linkedin.com/company/trav4college/" className="footer-item text-gray-400 hover:text-gray-900 dark:hover:text-blue-500">
                <AiFillLinkedin/>
            </a>
        </div>
    </div>
</footer>

    </>
  )
}

export default Footer