import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "../../App";

const Router = () => {
    return(
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    )
}

export default Router