import axios  from 'axios';
import { DataToSend, SubscribeState, SubscribeResponse } from '../../Utils/Model';
import { createAsyncThunk, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CONFIG } from '../../Utils/Constants/Header';
import { API } from '../../Utils/Constants/API';

const initialState = {
  loading: false,
  error: null,
  data: null
} as SubscribeState

export const subscribeToBlog = createAsyncThunk('susbscribe/subscribeToBlog', async(data: DataToSend, thunkApi) => {
    try {
        const response = await axios.post<SubscribeResponse>(`${API}/subscribe`, data, CONFIG);
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue(error.message)
    }
})

const subscribeBlogSlice = createSlice({
   name: 'subscribetoblog',
   initialState,
   reducers: {},
   extraReducers(builder){
        builder
        .addCase(subscribeToBlog.pending, (state) => {
            state.loading = true
        })
        .addCase(subscribeToBlog.fulfilled, (state, action: PayloadAction<SubscribeResponse>) => {
            state.loading = false;
            state.data = action.payload;
        })
        .addCase(subscribeToBlog.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.error = action.payload
        })
   }
})

export default subscribeBlogSlice.reducer