const Loaders = () => {
    return (
        <>
        <div className="rounded-md p-4 max-w-3xl w-full mx-auto mt-12">
        <div className="animate-pulse flex space-x-4 ">
          <div className="rounded bg-blue-200 h-36 w-36"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-blue-200 rounded"></div>
            <div className="h-2 bg-blue-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-blue-200 rounded col-span-2"></div>
                <div className="h-2 bg-blue-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-blue-200 rounded"></div>
            </div>
      
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-blue-200 rounded col-span-2"></div>
                <div className="h-2 bg-blue-200 rounded-full col-span-1"></div>
              </div>
              <div className="h-2 bg-blue-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="rounded-md p-4 max-w-3xl w-full mx-auto mt-12">
        <div className="animate-pulse flex space-x-4 ">
          <div className="rounded bg-blue-200 h-36 w-36"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-blue-200 rounded"></div>
            <div className="h-2 bg-blue-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-blue-200 rounded col-span-2"></div>
                <div className="h-2 bg-blue-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-blue-200 rounded"></div>
            </div>
      
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-blue-200 rounded col-span-2"></div>
                <div className="h-2 bg-blue-200 rounded-full col-span-1"></div>
              </div>
              <div className="h-2 bg-blue-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>


      <div className="rounded-md p-4 max-w-3xl w-full mx-auto mt-12">
        <div className="animate-pulse flex space-x-4 ">
          <div className="rounded bg-blue-200 h-36 w-36"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-blue-200 rounded"></div>
            <div className="h-2 bg-blue-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-blue-200 rounded col-span-2"></div>
                <div className="h-2 bg-blue-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-blue-200 rounded"></div>
            </div>
      
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-blue-200 rounded col-span-2"></div>
                <div className="h-2 bg-blue-200 rounded-full col-span-1"></div>
              </div>
              <div className="h-2 bg-blue-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>


      <div className="rounded-md p-4 max-w-3xl w-full mx-auto mt-12">
        <div className="animate-pulse flex space-x-4 ">
          <div className="rounded bg-blue-200 h-36 w-36"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-blue-200 rounded"></div>
            <div className="h-2 bg-blue-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-blue-200 rounded col-span-2"></div>
                <div className="h-2 bg-blue-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-blue-200 rounded"></div>
            </div>
      
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-blue-200 rounded col-span-2"></div>
                <div className="h-2 bg-blue-200 rounded-full col-span-1"></div>
              </div>
              <div className="h-2 bg-blue-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>

        </>
    )
}

export default Loaders