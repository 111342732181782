import { BlogResponse } from '../../Utils/Model';
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios  from 'axios';
import { API } from "../../Utils/Constants/API";
import { CONFIG } from "../../Utils/Constants/Header";
import { BlogState } from "../../Utils/Model";
// import axiosInstance from "../Utils/helpers/axiosInstance";

const initialState = {
   loading: false,
   blogs: null,
   error: null
} as BlogState

// ACTIONS
export const fetchBlogContents = createAsyncThunk('blog/fetchBlogContents', async (data: number = 1, thunkApi) => {
    try {
        const response = await axios.get<BlogResponse>(`${API}/blogs?page=${data}`, CONFIG)
        return response.data
    } catch (error: any) {
       return thunkApi.rejectWithValue(error.message)
    }
})

// SLICE
const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {},
    extraReducers (builder) {
        //cases
        builder //chainig cases
        .addCase(fetchBlogContents.pending, (state) => {
            state.loading = true
        })
        .addCase(fetchBlogContents.fulfilled, (state, action: PayloadAction<BlogResponse>) => {
            state.loading = false
            state.blogs = action.payload
            state.error = ''
        })
        .addCase(fetchBlogContents.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload.message || 'Something is wrong'
        })
    }
})

export default blogSlice.reducer